/*
 * File: provider.ts
 * Project: galilea-centro
 * File Created: Tuesday, 19th May 2020 12:56:50 am
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Bank, City, County, LegalRepresentative, Mutuality, PurchaseOrderType, SpecialtyContract } from '.';
import { Branch } from './branch';
import { Contract } from './contractor';
import { Specialty } from './specialty';
import { User } from './user';

export interface Provider {
  id: number;
  rut: string;
  name: string;
  commonName?: string;
  legalName: string;
  retentionBalance?: number;
  thirdPartyInvoice: boolean;
  type: ProviderType;
  currentContract?: Contract;
  currentSpecialtyContract?: SpecialtyContract;
  specialties?: Specialty[];
  activity: string;
  legalType: ProviderLegalType;
  address: string;
  county: County;
  city: City;
  mutuality: Mutuality;
  phoneNumber: string;
  email: string;
  paymentPeriod: string;
  paymentMethod: string;
  bank: Bank;
  bankAccountType: string;
  bankAccountNumber: string;
  status: ProviderStatus;
  branches: Partial<Branch>[];
  enabled: boolean;
  comment: string;
  updatedBy?: User;
  updatedAt?: string;
  requestedBy?: User;
  requestedAt?: string;
  approvedBy?: User;
  approvedAt?: string;
  legalRepresentatives?: LegalRepresentative[];
  blocked?: boolean;
}

// eslint-disable-next-line no-shadow
export enum ProviderStatus {
  DRAFT = 'DRAFT',
  PENDING_MATERIAL_SERVICE = 'PENDING_MATERIAL_SERVICE',
  PENDING_CONTRACTOR = 'PENDING_CONTRACTOR',
  ACTIVE = 'ACTIVE',
}

// eslint-disable-next-line no-shadow
export enum ProviderLegalType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
  LEGAL_ONE_DAY = 'LEGAL_ONE_DAY',
  SERVICE = 'SERVICE',
}

// eslint-disable-next-line no-shadow
export enum ProviderType {
  /**
   * Provider only sells materials
   * i.e Material PO only
   */
  MATERIAL = 'MATERIAL',

  /**
   * Provider only provides services
   * i.e Service PO only
   */
  SERVICE = 'SERVICE',

  /**
   * Provider provides both materials and services
   * i.e Material and services PO
   */
  MATERIAL_SERVICE = 'MATERIAL_SERVICE',

  /**
   * Provider is a contractor
   * i.e Can be used in labor
   */
  CONTRACTOR = 'CONTRACTOR',

  /**
   * Provider is an in-house worker
   * i.e Can be used in labor
   */
  IN_HOUSE = 'IN_HOUSE',

  /**
   * Provider is a contractor that also sells materials
   * i.e Can be used in labor and material POs
   */
  CONTRACTOR_MATERIAL = 'CONTRACTOR_MATERIAL',

  /**
   * Provider is a contractor that also provides services
   * i.e Can be used in labor and service POs
   */
  CONTRACTOR_SERVICE = 'CONTRACTOR_SERVICE',

  /**
   * Provider is a contractor that also sells materials and provides services
   * i.e Can be used in labor, material and service POs
   */
  CONTRACTOR_SERVICE_MATERIAL = 'CONTRACTOR_SERVICE_MATERIAL',

  /**
   * In-house worker that also sells materials
   * i.e Can be used in labor and material POs
   */
  IN_HOUSE_MATERIAL = 'IN_HOUSE_MATERIAL',

  /**
   * In-house worker that also provides services
   * i.e Can be used in labor and service POs
   */
  IN_HOUSE_SERVICE = 'IN_HOUSE_SERVICE',

  /**
   * In house worker that also sells materials and provides services
   * i.e Can be used in labor, material and service POs
   */
  IN_HOUSE_SERVICE_MATERIAL = 'IN_HOUSE_SERVICE_MATERIAL',
}

export function translateProviderType(s: string): ProviderType {
  switch (s?.toUpperCase()) {
    case 'MATERIAL':
      return ProviderType.MATERIAL;
    case 'SERVICE':
      return ProviderType.SERVICE;
    case 'MATERIAL_SERVICE':
      return ProviderType.MATERIAL_SERVICE;
    case 'CONTRACTOR':
      return ProviderType.CONTRACTOR;
    case 'IN_HOUSE':
      return ProviderType.IN_HOUSE;
    case 'CONTRACTOR_MATERIAL':
      return ProviderType.CONTRACTOR_MATERIAL;
    case 'CONTRACTOR_SERVICE':
      return ProviderType.CONTRACTOR_SERVICE;
    case 'CONTRACTOR_SERVICE_MATERIAL':
      return ProviderType.CONTRACTOR_SERVICE_MATERIAL;
    case 'IN_HOUSE_MATERIAL':
      return ProviderType.IN_HOUSE_MATERIAL;
    case 'IN_HOUSE_SERVICE':
      return ProviderType.IN_HOUSE_SERVICE;
    case 'IN_HOUSE_SERVICE_MATERIAL':
      return ProviderType.IN_HOUSE_SERVICE_MATERIAL;
    default:
      return null;
  }
}

export const providersContractorsTypes = [
  ProviderType.CONTRACTOR,
  ProviderType.CONTRACTOR_SERVICE,
  ProviderType.CONTRACTOR_MATERIAL,
  ProviderType.CONTRACTOR_SERVICE_MATERIAL,
  ProviderType.IN_HOUSE,
  ProviderType.IN_HOUSE_SERVICE,
  ProviderType.IN_HOUSE_MATERIAL,
  ProviderType.IN_HOUSE_SERVICE_MATERIAL,
];

export const materialProvidersTypes = [
  ProviderType.MATERIAL,
  ProviderType.MATERIAL_SERVICE,
  ProviderType.CONTRACTOR_MATERIAL,
  ProviderType.CONTRACTOR_SERVICE_MATERIAL,
  ProviderType.IN_HOUSE_MATERIAL,
  ProviderType.IN_HOUSE_SERVICE_MATERIAL,
];

export const serviceProvidersTypes = [
  ProviderType.SERVICE,
  ProviderType.MATERIAL_SERVICE,
  ProviderType.CONTRACTOR_SERVICE,
  ProviderType.CONTRACTOR_SERVICE_MATERIAL,
  ProviderType.IN_HOUSE_SERVICE,
  ProviderType.IN_HOUSE_SERVICE_MATERIAL,
];

export function getProvidersTypeByPOType(purchaseOrderType: PurchaseOrderType): ProviderType[] {
  if (purchaseOrderType === PurchaseOrderType.LABOR) {
    return providersContractorsTypes;
  } else if (purchaseOrderType === PurchaseOrderType.MATERIAL) {
    return materialProvidersTypes;
  } else {
    return serviceProvidersTypes;
  }
}
