import { User } from './user';

// eslint-disable-next-line no-shadow
export enum MaterialStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  PENDING_CORRECTION = 'PENDING_CORRECTION',
}

export interface Material {
  id?: number;
  name?: string;
  status?: MaterialStatus;
  enabled?: boolean;
  displayName?: string;
  materialFormats?: MaterialFormat[];
  deliveryFormat?: MaterialFormat;
  isGravel?: boolean;
  stockable?: boolean;
  dispatchers?: {
    id?: number;
    name?: string;
    rut?: string;
  }[];
  provider?: {
    id?: number;
    legalName?: string;
  };
  deliveryFormatId?: number;
  quantityConverted?: number;
  subcategory?: MaterialSubcategory;
  comment?: string;
  quantificationMetric?: string;
  createdBy?: User;
  createdAt?: string;
  updatedBy?: User;
  updatedAt?: string;
  requestedBy?: User;
  requestedAt?: string;
  approvedBy?: User;
  approvedAt?: string;
}

export interface MaterialSubcategory {
  id: number;
  name: string;
  category?: MaterialCategory;
  enabled: boolean;
  materialsAssigned?: number;
}

export interface MaterialCategory {
  id: number;
  name: string;
  enabled: boolean;
  createdBy?: User;
  createdAt?: string;
  updatedBy?: User;
  updatedAt?: string;
  materialSubcategories?: MaterialSubcategory[];
  materialsAssigned?: number;
  materialSubcategoriesNames?: string;
}

export interface MaterialFormat {
  id: number;
  name: string;
  conversion?: number;
}
