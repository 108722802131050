/*
 * File: oc.service.ts
 * Project: galilea-centro
 * File Created: Monday, 23rd March 2020 2:42:01 pm
 * Author: Felipe Rojos Almuna (felipe@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { GraphqlService } from './graphql.service';
import { UserInfo } from '@app/models/user';
import { Activity } from '@app/models/activity';

@Injectable({
  providedIn: 'root',
})
export class OcService {
  constructor(private graphql: GraphqlService) {}

  public fragments = {
    base: gql`
      fragment PurchaseOrderBasic2 on PurchaseOrder {
        id
        type
        status
        sheet
        provider {
          id
          rut
          commonName
          legalName
        }
        warehouse {
          id
          name
        }
        shippingResponsibleName
        shippingResponsibleRut
        uuid
        attachmentURL
        comments
        internalComment
      }
    `,
    dates: gql`
      fragment PurchaseOrderDates2 on PurchaseOrder {
        createdAt
        requestedAt
        authorizedAt
        sentAt
        expiredAt
        closedAt
        expiryDate
      }
    `,
    deliveryData: gql`
      fragment PurchaseOrderDeliveryData2 on PurchaseOrder {
        deliveryType
        contactName
        contactPhone
        shippingResponsibleName
        shippingResponsibleRut
        contactEmail
        shipToAddress
      }
    `,
    commonExtendedDetails: gql`
      fragment PurchaseOrderCommonExtendedDetails2 on PurchaseOrderDetail {
        quantityConverted: quantity
        priceAgreements {
          provider {
            id
            name: legalName
          }
          price
          currency
          materialFormat {
            id
          }
        }
        material {
          id
          name
          deliveryFormat {
            id
            name
          }
          materialFormats {
            id
            name
            conversion
          }
        }
        comments
        type
        finalAmount
        unitPrice
        receivedQuantity
        paidQuantity
        budgetPrice: currentBudgetPrice
        currency
        providerSKU
        additionalTax
        vatPercentage
        budget {
          id
          name
          budgetGroup {
            id
            name
            branch {
              id
              name
            }
          }
        }
        activity {
          id
          name
        }
        # deliveryFormatId
        deliveryFormat {
          id
          name
        }
      }
    `,
    documentedMaterialMovements: gql`
      fragment PurchaseOrderMaterialMovements on PurchaseOrder {
        documentedMaterialMovements {
          id
          purchaseOrderDetails {
            material {
              id
            }
            quantityConverted
            paidQuantityConverted
            pendingQuantityConverted
            purchaseOrderDetail {
              id
            }
          }
          createdAt
          createdBy {
            name
          }
          metadata {
            documentNumber
          }
        }
      }
    `,
  };

  // Used on receive-materials & receive-service
  public getPurchaseOrderSimple(id: number): QueryRef<
    Record<string, unknown>,
    {
      id: number;
    }
  > {
    return this.graphql.query(
      gql`
        query purchaseOrder($id: Int!) {
          purchaseOrder(id: $id) {
            id
            status
            sheet
            warehouse {
              id
              name
            }
            provider {
              id
              legalName
              rut
            }
            details(forceReturn: true) {
              id
              quantityConverted: quantity
              activity {
                id
                name
              }
              budget {
                id
                name
                budgetGroup {
                  id
                  name
                  branch {
                    id
                    name
                  }
                }
              }
              deliveryFormat {
                id
              }
              material {
                id
                name
                isGravel
                deliveryFormat {
                  id
                  name
                }
                materialFormats {
                  id
                  name
                  conversion
                }
              }
              providerSKU
            }
            attachmentURL
            shippingResponsibleName
            shippingResponsibleRut
            documentedMaterialMovements {
              id
              editable
              paid
              details {
                material {
                  id
                }
                quantityConverted
              }
              gravelReception {
                id
                ticket {
                  id
                  ticketDetails {
                    budget {
                      id
                      name
                      budgetGroup {
                        id
                        name
                      }
                    }
                  }
                  activity {
                    name
                  }
                }
              }
              metadata {
                documentType
                documentNumber
              }
              comments
              attachmentURLs
              createdAt
              createdBy {
                name
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        variables: {
          id,
        },
      },
    );
  }

  servicePurchaseOrderQuery = gql`
    query {
      userInfo {
        id
        branches {
          id
          name
        }
      }
      activities(params: { activityTypes: [SERVICE], filterBudget: false }) {
        id
        name
      }
    }
  `;
  forceServicePurchaseOrderQuery = (): QueryRef<
    {
      userInfo: UserInfo;
      activities: Activity[];
    },
    Record<string, unknown>
  > => {
    return this.graphql.query<{ userInfo: UserInfo; activities: Activity[] }>(this.servicePurchaseOrderQuery, {
      fetchPolicy: 'network-only',
    });
  };
}
