<label
  class="upload-component"
  [ngClass]="{ 'no-display': uploading || uploaded, disabled: disabled, 'is-invalid': isInvalid }"
>
  <mat-icon aria-hidden="false" aria-label="Example thumbs up SVG icon">file_upload</mat-icon>
  <input #fileInput type="file" (change)="upload($event.target.files)" class="no-display" />
  <span class="item-text">SUBIR ARCHIVO</span>
</label>
<label class="upload-component" [ngClass]="{ 'no-display': !(uploading || uploaded), disabled: disabled }">
  <mat-progress-bar [ngClass]="{ 'no-display': !uploading }" mode="determinate" [value]="progress"> </mat-progress-bar>
  <span [ngClass]="{ 'no-display': !uploading }" class="progress-percentage">{{ progress }}%</span>
  <a [ngClass]="{ 'no-display': !uploaded }" [href]="url" target="blank">{{ name }}</a>
  <mat-icon *ngIf="!disabled" [ngClass]="{ 'no-display': !uploaded }" (click)="processDelete()">delete</mat-icon>
</label>
