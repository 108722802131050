/* eslint-disable no-shadow */
/*
 * File?: tributaryDocument.ts
 * Project?: galilea-centro
 * File Created?: Thursday, 11th June 2020 11?:53?:26 am
 * Author?: Felipe Rojos Almuna (felipe@inventures.cl)
 * -----
 * Last Modified?: Thursday, 11th June 2020 12?:06?:59 pm
 * Modified By?: Felipe Rojos Almuna (felipe@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { PurchaseOrder } from './oc';
import { Material } from './materialItems';
import { Provider } from './provider';
import { Activity } from './activity';
import { Budget } from './budget';

export interface TributaryDocument {
  id: number;
  ufDate: string;
  vat?: number;
  netAmount?: number;
  totalAmount?: number;
  additionalTax?: number;
  documentNumber?: number;
  electronicUrl?: string;
  issuerActivity?: string;
  issuerAddress?: string;
  issuerName?: string;
  issuerRut?: string;
  issueDate?: string;
  accuseMaxDate?: string;
  accuseDangerDate?: string;
  status?: TributaryDocumentStatus;
  tributaryDocumentPayments?: TributaryDocumentPayment[];
  purchaseOrders?: PurchaseOrder[];
  allowedPaymentDifference?: number;
  blockType?: TributaryDocumentBlock;
  exemptAmount?: number;
  type?: TributaryDocumentType;
  issuerProvider?: Provider;
  receiverProvider?: Provider;
  issuerByRutProvider?: Provider;
  receiverByRutProvider?: Provider;
  rejectionReason?: TributaryDocumentRejectionReason;
  rejectionComment?: string;
  reviewedAt?: string;
  createdAt?: string;
}
export interface TributaryDocumentPayment {
  material?: Material;
  activity?: Activity;
  budget?: Budget;
  purchaseOrderDetailId: number;
  quantityConverted?: number;
}

/**
 * Status the Invoice document is in
 *
 * @export
 * @enum {number}
 */
// eslint-disable-next-line no-shadow
export enum TributaryDocumentStatus {
  // Tributary document hasn't been reviewed yet
  PENDING = 'PENDING',
  // Tributary Document has been accepted and informed to Internal Tax Service
  ACCEPTED = 'ACCEPTED',
  // Tributary Document has been rejected and informed to Internal Tax Service
  REJECTED = 'REJECTED',
  // Tributary Document has been marked for rejection on fetching
  PENDING_REJECTION = 'PENDING_REJECTION',
  // Tributary Document has already paid, and must be matched with a purchase order.
  PENDING_MATCH = 'PENDING_MATCH',
  // Tributary Document has already paid, and must be matched with a purchase order.
  WIP = 'WIP',
}

export const processTributaryDocStatus = (status: TributaryDocumentStatus): string => {
  switch (status) {
    case TributaryDocumentStatus.PENDING:
      return 'Por validar';
    case TributaryDocumentStatus.ACCEPTED:
      return 'Aceptada';
    case TributaryDocumentStatus.REJECTED:
      return 'Rechazada';
    case TributaryDocumentStatus.PENDING_REJECTION:
      return 'Por rechazar';
    case TributaryDocumentStatus.PENDING_MATCH:
      return 'Por calzar';
    case TributaryDocumentStatus.WIP:
      return 'Proceso de aceptación';
    default:
      return status;
  }
};
export const processTributaryDocumentRejectionReason = (reason: TributaryDocumentRejectionReason): string => {
  switch (reason) {
    case TributaryDocumentRejectionReason.OTHER: {
      return 'Otro motivo';
    }
    case TributaryDocumentRejectionReason.PRICE: {
      return 'Precio';
    }
    case TributaryDocumentRejectionReason.PURCHASE_ORDER_PENDING_RECEPTION: {
      return 'Orden de compra pendiente de recepción';
    }
    case TributaryDocumentRejectionReason.PURCHASE_ORDER_MISSING: {
      return 'Sin orden de compra';
    }
    case TributaryDocumentRejectionReason.PURCHASE_ORDER_EXPIRED: {
      return 'Orden de compra Expirada';
    }
    case TributaryDocumentRejectionReason.AUTOMATIC_EXPIRE: {
      return 'Factura autoexpirada';
    }
    case TributaryDocumentRejectionReason.PURCHASE_ORDER_DOESNT_BELONG: {
      return 'Orden de compra no corresponde a proveedor';
    }
    case TributaryDocumentRejectionReason.PURCHASE_ORDER_INVALID_STATE: {
      return 'Orden de compra no valida para recibir factura';
    }
    case TributaryDocumentRejectionReason.INSUFFICENT_BALANCE: {
      return 'No hay saldo suficiente para esta factura';
    }
    default:
      return '';
  }
};

export enum TributaryDocumentBlock {
  // No lock applied
  NO_LOCK = 'NO_LOCK',
  // Normal lock applied
  NORMAL_LOCK = 'NORMAL_LOCK',
  // Rendition needed
  RENDITION_LOCK = 'RENDITION_LOCK',
}

export enum TributaryDocumentType {
  FACTURA = 'FACTURA',
  FACTURA_EXCENTA = 'FACTURA_EXCENTA',
  FACTURA_COMPRA = 'FACTURA_COMPRA',
  FACTURA_LIQUIDACION = 'FACTURA_LIQUIDACION',
  GUIA_DESPACHO = 'GUIA_DESPACHO',
  NOTA_DEBITO = 'NOTA_DEBITO',
  NOTA_CREDITO = 'NOTA_CREDITO',
  FACTURA_EXPORTACION = 'FACTURA_EXPORTACION',
  NOTA_DEBITO_EXPORTACION = 'NOTA_DEBITO_EXPORTACION',
  NOTA_CREDITO_EXPORTACION = 'NOTA_CREDITO_EXPORTACION',
  OTHER = 'OTHER',
}

export enum TributaryDocumentRejectionReason {
  OTHER = 'OTHER',
  PRICE = 'PRICE',
  PURCHASE_ORDER_PENDING_RECEPTION = 'PURCHASE_ORDER_PENDING_RECEPTION',
  PURCHASE_ORDER_MISSING = 'PURCHASE_ORDER_MISSING',
  PURCHASE_ORDER_EXPIRED = 'PURCHASE_ORDER_EXPIRED',
  AUTOMATIC_EXPIRE = 'AUTOMATIC_EXPIRE',
  PURCHASE_ORDER_DOESNT_BELONG = 'PURCHASE_ORDER_DOESNT_BELONG',
  PURCHASE_ORDER_INVALID_STATE = 'PURCHASE_ORDER_INVALID_STATE',
  INSUFFICENT_BALANCE = 'INSUFFICENT_BALANCE',
}

export enum SIITributaryDocumentType {
  FACTURA = 33,
  FACTURA_EXCENTA = 34,
  FACTURA_LIQUIDACION = 43,
  FACTURA_COMPRA = 46,
  GUIA_DESPACHO = 52,
  NOTA_DEBITO = 56,
  NOTA_CREDITO = 61,
  FACTURA_EXPORTACION = 110,
  NOTA_DEBITO_EXPORTACION = 111,
  NOTA_CREDITO_EXPORTACION = 112,
}

export const TributaryDocumentTypeTranslate = {
  33: TributaryDocumentType.FACTURA,
  34: TributaryDocumentType.FACTURA_EXCENTA,
  43: TributaryDocumentType.FACTURA_LIQUIDACION,
  46: TributaryDocumentType.FACTURA_COMPRA,
  52: TributaryDocumentType.GUIA_DESPACHO,
  56: TributaryDocumentType.NOTA_DEBITO,
  61: TributaryDocumentType.NOTA_CREDITO,
  110: TributaryDocumentType.FACTURA_EXPORTACION,
  111: TributaryDocumentType.NOTA_DEBITO_EXPORTACION,
  112: TributaryDocumentType.NOTA_CREDITO_EXPORTACION,
};

export function translateTributaryDocumentType(s: string): TributaryDocumentType {
  switch (s?.toUpperCase()) {
    case 'FACTURA':
      return TributaryDocumentType.FACTURA;
    case 'FACTURA_EXCENTA':
      return TributaryDocumentType.FACTURA_EXCENTA;
    case 'FACTURA_LIQUIDACION':
      return TributaryDocumentType.FACTURA_LIQUIDACION;
    case 'FACTURA_COMPRA':
      return TributaryDocumentType.FACTURA_COMPRA;
    case 'GUIA_DESPACHO':
      return TributaryDocumentType.GUIA_DESPACHO;
    case 'NOTA_DEBITO':
      return TributaryDocumentType.NOTA_DEBITO;
    case 'NOTA_CREDITO':
      return TributaryDocumentType.NOTA_CREDITO;
    case 'FACTURA_EXPORTACION':
      return TributaryDocumentType.FACTURA_EXPORTACION;
    case 'NOTA_DEBITO_EXPORTACION':
      return TributaryDocumentType.NOTA_DEBITO_EXPORTACION;
    case 'NOTA_CREDITO_EXPORTTACION':
      return TributaryDocumentType.NOTA_CREDITO_EXPORTACION;
    default:
      return null;
  }
}

export function processTributaryDocumentType(status: TributaryDocumentType): string {
  switch (status) {
    case TributaryDocumentType.FACTURA:
      return 'Factura';
    case TributaryDocumentType.FACTURA_EXCENTA:
      return 'Factura exenta';
    case TributaryDocumentType.FACTURA_LIQUIDACION:
      return 'Factura liquidación';
    case TributaryDocumentType.FACTURA_COMPRA:
      return 'Factura compra';
    case TributaryDocumentType.GUIA_DESPACHO:
      return 'Guía despacho';
    case TributaryDocumentType.NOTA_DEBITO:
      return 'Nota débito';
    case TributaryDocumentType.NOTA_CREDITO:
      return 'Nota crédito';
    case TributaryDocumentType.FACTURA_EXPORTACION:
      return 'Factura exportación';
    case TributaryDocumentType.NOTA_DEBITO_EXPORTACION:
      return 'Nota débito exportación';
    case TributaryDocumentType.NOTA_CREDITO_EXPORTACION:
      return 'Nota crédito exportación';
    case TributaryDocumentType.OTHER:
      return 'Guía de Terceros';
    default:
      return status;
  }
}
// Partial enum
export enum TributaryDocumentReferenceType {
  ELECTRONIC_INVOICE = 33,
  VAT_EXCEPMT_ELECTRONIC_INVOICE = 34,
}

export const getInvoiceTypes = (): TributaryDocumentType[] => {
  return [TributaryDocumentType.FACTURA, TributaryDocumentType.FACTURA_EXCENTA, TributaryDocumentType.FACTURA_COMPRA];
};

export const getNotInvoiceTypes = (): TributaryDocumentType[] => {
  return [
    TributaryDocumentType.FACTURA_LIQUIDACION,
    TributaryDocumentType.GUIA_DESPACHO,
    TributaryDocumentType.NOTA_DEBITO,
    TributaryDocumentType.NOTA_CREDITO,
    TributaryDocumentType.FACTURA_EXPORTACION,
    TributaryDocumentType.NOTA_DEBITO_EXPORTACION,
    TributaryDocumentType.NOTA_CREDITO_EXPORTACION,
  ];
};
