/*
 * File: form-card.component.ts
 * Project: galilea-centro
 * File Created: Tuesday, 13th April 2021 2:24:21 pm
 * Author: Fabián Ulloa (fabian@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Component, OnInit, Input } from '@angular/core';
import { Selection } from '../select/select.component';

// eslint-disable-next-line no-shadow
export enum FormCardType {
  INFO = 'info',
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  FILE = 'file',
  DOUBLE_INFO = 'doubleinfo',
  INFO_AND_BUTTON = 'infoandbutton',
  NUMBER_INPUT = 'numberinput',
  MULTISELECT = 'multiselect',
  DATE_PICKER = 'datepicker',
}

export type CardSize = 'simple' | 'double';

interface CommonData {
  title: string;
  type: FormCardType;
  hidden?: boolean;
  icon?: string;
  iconCallback?: () => void;
  iconTooltip?: string;
  editable?: boolean;
  //Add a line break after the card
  breakLine?: boolean;
  size?: CardSize;
}

export interface InfoAndButton extends CommonData {
  type: FormCardType.INFO_AND_BUTTON;
  content: {
    info: string;
    buttonText: string;
    buttonCallback: () => void;
  };
}
export interface InfoCard extends CommonData {
  type: FormCardType.INFO;
  content: {
    info: string;
  };
}

export interface DoubleInfoCard extends CommonData {
  type: FormCardType.DOUBLE_INFO;
  content: {
    left: string;
    right: string;
    widths?: [number, number];
  };
}

export interface ButtonCard extends CommonData {
  type: FormCardType.BUTTON;
  content: {
    buttonText: string;
    buttonIcon: string;
    buttonCallback: () => void;
  };
}
export interface DropdownCard extends CommonData {
  type: FormCardType.DROPDOWN;
  content: {
    selections: Selection[];
    currentValue: string;
    enableClearButton?: boolean;
    onSelectCallback: (event: { selection: string; attr: string }) => void;
    isInvalid?: boolean;
  };
  searchable?: boolean;
}

export interface InputCard extends CommonData {
  type: FormCardType.INPUT;
  content: {
    currentValue: string;
    onChangeCallback: (value: string) => void;
    pattern?: string;
    regex?: RegExp;
    placeholder?: string;
    isInvalid?: boolean;
  };
}

export interface FileCard extends CommonData {
  type: FormCardType.FILE;
  content: {
    currentValue: string;
    meta: string;
    disabled?: boolean;
    onChangeCallback: (value: string) => void;
    isInvalid?: boolean;
  };
}

export interface NumberInputCard extends CommonData {
  type: FormCardType.NUMBER_INPUT;
  content: {
    currentValue: number;
    sufixText: string;
    onChangeCallback: (value: number) => void;
    interval: number;
    min?: number;
    max?: number;
    softMin?: number;
    softMax?: number;
    precision?: number;
  };
}

export interface MultiselectCard extends CommonData {
  type: FormCardType.MULTISELECT;
  content: {
    selections: Selection[];
    currentValue: string[];
    enableClearButton?: boolean;
    onSelectCallback: (event: { selection: string[]; attr: string }) => void;
  };
}

export interface DatePickerCard extends CommonData {
  type: FormCardType.DATE_PICKER;
  content: {
    currentValue: Date;
    onChangeCallback: (value: Date) => void;
    isInvalid?: boolean;
    dateFilter?: (day: Date) => boolean;
    min?: Date;
  };
}

export type FormCardData =
  | InfoCard
  | DoubleInfoCard
  | ButtonCard
  | DropdownCard
  | InputCard
  | FileCard
  | InfoAndButton
  | NumberInputCard
  | MultiselectCard
  | DatePickerCard;

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent implements OnInit {
  @Input() data: FormCardData;

  ngOnInit(): void {}

  onButtonClick(): void {
    if (this.data.type === FormCardType.BUTTON || this.data.type === FormCardType.INFO_AND_BUTTON)
      this.data.content.buttonCallback();
  }

  onDropdownSelect(event: { selection: string; attr: string }): void {
    if (this.data.type === FormCardType.DROPDOWN) this.data.content.onSelectCallback(event);
  }

  onMultiselectSelect(event: { selection: string[]; attr: string }): void {
    if (this.data.type === FormCardType.MULTISELECT) this.data.content.onSelectCallback(event);
  }

  onInputChange(value: string | number | Date): void {
    if (this.data.type === FormCardType.INPUT || this.data.type === FormCardType.FILE)
      this.data.content.onChangeCallback(String(value));
    else if (this.data.type === FormCardType.NUMBER_INPUT) this.data.content.onChangeCallback(Number(value));
    else if (this.data.type === FormCardType.DATE_PICKER) this.data.content.onChangeCallback(new Date(value));
  }

  getContentAttribute(attr: string): string {
    return this.data.content[attr];
  }

  getContentSelections(): Selection[] {
    if (this.data.type === FormCardType.DROPDOWN || this.data.type === FormCardType.MULTISELECT) {
      return this.data.content.selections;
    }
    return [];
  }

  getSearchable(): boolean {
    if (this.data.type === FormCardType.DROPDOWN) {
      return this.data?.searchable;
    }
    return false;
  }

  getCurrentValue(): string | number | Date {
    if (
      this.data.type === FormCardType.INPUT ||
      this.data.type === FormCardType.NUMBER_INPUT ||
      this.data.type === FormCardType.DATE_PICKER
    ) {
      return this.data?.content.currentValue;
    }
    return null;
  }
}
